import { Button, Flex, FormControl, FormLabel, Input, Box, Card, CardBody, Select, Image, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from "react";
import BgButton from "./BgButton";
import api from "../utils/api";

const schema = yup.object({
    name: yup.string().required(),
    price: yup.number(),
    sell: yup.boolean(),
    height: yup.number().required(),
    year: yup.number().required(),
    width: yup.number().required(),
    category_id: yup.number().required(),
    type_paint_id: yup.number().required(),
    substrate_id: yup.number().required(),
});

const ImageForm = ({close, categoryID, editMode, imageData}) => {
    const toast = useToast()
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, watch, reset, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(()=>{
        setValue('price', 0)
        setValue('category_id', categoryID)
    },[categoryID])
    useEffect(()=>{
        if(editMode){
            reset(imageData)
            setValue('category_id', Number(categoryID))
        }
    },[editMode])
    const addImage = () => {
        document.getElementById('uploadImageInput').click()
    }
    const setFile = e => {
        if(e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
                file: e.target.files[0]
            })
        }
    }
    const setForSell = sell => {
        setValue('sell', sell)
        if(!sell)
            setValue('price', 0)
    }
    const submit = (data) => {
        setLoading(true)
        if(editMode)
            update(data)
        else
            newItem(data)
    }
    const newItem = async(data) => {
        const formData = new FormData();
        formData.append('image', img.file)
        for ( var key in data ) {
            formData.append(key, data[key]);
        }
        const res = await api.post('/gallery/new', formData)
        if(res.status === 201){
            done()
            close(true)
        }
        setLoading(false)
    }
    const update = async(data) => {
        const res = await api.post(`/gallery/update/${categoryID}/${imageData.id}`, data)
        if(res.status === 200) done()
        setLoading(false)
    }
    const done = () => {
        toast({
            title: 'Sukces.',
            description: `Obraz został ${editMode ? 'zapisany' : 'dodany'}.`,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }
    return <Card w={'full'}>
        <CardBody>
            <form onSubmit={handleSubmit(submit)}>
                <Flex flexFlow={'column'} gap={'1rem'}>
                    <FormControl>
                        <FormLabel>Nazwa</FormLabel>
                        <Input {...register('name')} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Na sprzedaż</FormLabel>
                        <Flex w={'full'} gap={'1rem'}>
                            <BgButton text={'TAK'} val={true} action={setForSell} isActive={watch('sell')} />
                            <BgButton text={'NIE'} val={false} action={setForSell} isActive={!watch('sell')}/>
                        </Flex>
                    </FormControl>
                    {
                        watch('sell') ? 
                        <FormControl>
                            <FormLabel>Cena</FormLabel>
                            <Input {...register('price')} />
                        </FormControl> : ''
                    }
                    <FormControl>
                        <FormLabel>Technika</FormLabel>
                        <Select {...register('type_paint_id')}>
                            <option value={1}>akryl</option>
                            <option value={2}>olej</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Podłoże</FormLabel>
                        <Select {...register('substrate_id')}>
                            <option value={1}>płótno</option>
                            <option value={2}>drewno</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Rok</FormLabel>
                        <Input {...register('year')} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Format - szerokość</FormLabel>
                        <Input {...register('width')} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Format - wysokość</FormLabel>
                        <Input {...register('height')} />
                    </FormControl>
                    {
                        editMode ? '' :
                        <FormControl>
                            <FormLabel>Obraz</FormLabel>
                            { img ? <Image src={img.src} maxWidth={'300px'} maxH={'300px'} objectFit={'cover'} /> : '' }
                            <Button my={3} w={'full'} colorScheme={'blue'} variant={'outline'} onClick={addImage}>{img ? 'ZMIEŃ' : 'DODAJ'} OBRAZ</Button>
                            <input style={{display: 'none'}} id="uploadImageInput" type={'file'} accept="image/*" onChange={setFile} />
                        </FormControl>
                    }
                    <Flex justifyContent={'space-between'} mt={5}>
                        {editMode ? <div></div> : <Button onClick={close}>Anuluj</Button>}
                        <Button colorScheme={'green'} type={'submit'} isLoading={loading} loadingText='Zapsiuje...' spinnerPlacement='end'>Zapisz</Button>
                    </Flex>
                </Flex>
            </form>
        </CardBody>
    </Card>
}

export default ImageForm;