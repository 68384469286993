import { Flex, Box, Image } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

const ImagePreview = ({image: {id, name, url}}) => {
    const navigate = useNavigate();
    const { categoryID } = useParams();
    const handleClick = () => {
        navigate(`/images/${categoryID}/${id}`)
    }
    return <Box cursor={'pointer'} onClick={handleClick}>
        <Image 
            src={'https://api.hankagasienica.pl/media/' + url.replace('/www/hanka/img/gallery/','')} 
            alt={`image-${id}`}
            height={{base: '225px', md: '175px', lg: '225px', xl: '300px'}} 
            width={'full'} 
            objectFit={'cover'}
            lazy />
        <Flex 
            justifyContent={'center'} 
            alignItems={'center'} 
            p={4} 
            boxShadow={'lg'} 
            bg={'white'}
        >{name}</Flex>
    </Box>
}

export default ImagePreview;