import { SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../utils/api";
import Category from "./Category";

const CategoriesList = ({clickAction}) => {
    const [list, setList] = useState([])
    useEffect(()=> {
        const getData = async() => {
            try {
                const res = await api.get('/categories/fetch')
                setList(res.data.records)
            } catch (error) {
                console.log(error)
            }
        }
        getData()
    }, [])
    const selectCategory = categoryId => {
        const category = list.find(c => c.ID === categoryId)
        clickAction(category)
    }
    return <SimpleGrid gap={'1.5rem'} columns={{base: 1, md: 2, lg: 2, xl: 2, '2xl':3}}>
        {list.map(category => <Category key={category.ID} category={category} selectCategory={selectCategory} />)}
    </SimpleGrid>
}

export default CategoriesList;