import { Card, CardBody, Container, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../utils/api";

const DashboardBox = ({name, value, displayTitles, subTitle, subVal}) => {
    return <Card>
        <CardBody>
            {
                displayTitles ?
                <Flex fontSize={'sm'} color={'gray.500'} justifyContent={'space-between'}>
                    <Text>{subTitle}</Text>
                    <Text>{subVal}</Text>
                </Flex> : ''
            }
            <Flex fontSize={'lg'} justifyContent={'space-between'}>
                <Text>{name}</Text>
                <Text fontWeight={'bold'}>{value}</Text>
            </Flex>
        </CardBody>
    </Card>
}

const Dashboard = () => {
    const [images, setImages] = useState([]);
    const [categories, setCategories] = useState([]);
    // const [shop, setShop] = useState([])
    // const [exhibitions, setExhibitions] = useState([])
    useEffect(()=>{
        getData()
    },[])
    const getData = async() => {
        try {
            const resImages = await api.get('/gallery/fetch')
            setImages(resImages.data.records)
            // const resShop = await api.get('/shop/orders')
            // setShop(resShop.data.records)
            // const resExhibitions = await api.get('/exhibitions/list')
            // setExhibitions(resExhibitions.data.records)
            getCategories(resImages.data.records)
        } catch (error) {
            console.log(error)
        }
    }
    const getCategories = (imagesList) => {
        const arr = [];
        imagesList.forEach(image => {
            const index = arr.findIndex(c => c.name === image.categoryName)
            if(index > -1){
                arr[index].images.push(image)
            }else{
                arr.push({
                    name: image.categoryName,
                    images: [image]
                })
            }
        })
        setCategories(arr)
    }
    return <Container maxW={'7xl'}>
        <Flex flexFlow={'column'} gap={'1rem'}>
            <Flex my={4}>
                <Text fontWeight={'600'} fontSize={'2xl'}>Witaj w panelu administracyjnym!</Text>
            </Flex>
            <SimpleGrid columns={{base: 1, md: 2}} gap={2}>
                <DashboardBox name={'Kategorie'} value={categories.length}/>
                <DashboardBox name={'Obrazy'} value={images.length}/>
            </SimpleGrid>
            <SimpleGrid columns={{base: 1, md: 2, '2xl': 3}} gap={2}>
                {categories.map((c, i) => <DashboardBox key={i} name={c.name} value={c.images.length} displayTitles subTitle={'Kategoria'} subVal={'Ilośc obrazów'} />) }
            </SimpleGrid>
        </Flex>
    </Container>
}

export default Dashboard;