import { Flex, Button, Container, Heading, Text, Divider, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CategoriesList from "../components/CategoriesList";
import ImageForm from "../components/ImageForm";
import ImagesList from "../components/ImagesList";
import api from "../utils/api";

const Images = () => {
    const [setlectedCategory, setSetlectedCategory] = useState(null);
    const [imageForm, setImageForm] = useState(false);
    const [imagesList, setImagesList] = useState([]);
    const { categoryID } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        setImageForm(false)
    },[])
    useEffect(()=>{
        if(categoryID){
            getData()
        }
    },[categoryID])
    const getData = async() => {
        try {
            const res = await api.get(`/gallery/getByCategory/${categoryID}`)
            setImagesList(res.data.records)
        } catch (error) {
            console.log(error)
        }
    }
    const openCategory = category => {
        navigate(`/images/${category.ID}`)
        setSetlectedCategory(category)
    }
    const openForm = image => {
        setImageForm(true)
    }
    const close = (refresh=false) => {
        setImageForm(false)
        if(refresh)
            getData()
    }
    return <Container maxW={'5xl'} minHeight={'100vh'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Heading>Obrazy</Heading>
            {categoryID ? <Button colorScheme={'blue'} onClick={openForm}>Dodaj obraz</Button> : ''}
        </Flex>
        <Divider my={4} />
        {!categoryID ?
        <>
            <Text my={4} textAlign={'center'} pt={4}>Wybierz kategorie</Text>
            <CategoriesList clickAction={openCategory} />
        </>
        : imageForm ? <ImageForm close={close} categoryID={categoryID} /> : <ImagesList images={imagesList} category={setlectedCategory} /> }
    </Container>
}

export default Images;