import { Flex, Box, Image } from "@chakra-ui/react";

const Category = ({category: {ID, name ,url}, selectCategory}) => {
    return <Box cursor={'pointer'} onClick={()=>selectCategory(ID)}>
        <Image 
            src={'https://api.hankagasienica.pl/media/' + url.replace('/www/hanka/img/categories/','')} 
            alt={`image-${ID}`}
            height={{base: '225px', md: '175px', lg: '225px', xl: '300px'}} 
            width={'full'} 
            objectFit={'cover'} 
            lazy/>
        <Flex 
            justifyContent={'center'} 
            alignItems={'center'} 
            p={4} 
            boxShadow={'lg'} 
            bg={'white'}
        >{name}</Flex>
    </Box>
}

export default Category;