import { Button, Checkbox, Flex, FormControl, FormLabel, Input, Textarea, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from "react";
import BgButton from "./BgButton";
import api from "../utils/api";

const schema = yup.object({
    name: yup.string().required(),
    location: yup.string().required(),
    city: yup.string().required(),
    description: yup.string(),
    dateFrom: yup.date().required(),
});

const ExhibitionsForm = ({exhibition, setDisplayForm}) => {
    const toast = useToast()
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, watch, reset, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(()=>{
        if(exhibition){
            reset(exhibition)
            setValue('dateFrom', setDate(exhibition.dateFrom))
            if(Date.parse(exhibition.dateTo)){
                setValue('fewDays', true)
                setValue('dateTo', setDate(exhibition.dateTo))
            }
            console.log(exhibition)
        }else{
            setValue('description', '')
        }
    },[])
    const setDate = (date) => {
        const d = new Date(date);
        d.setHours(d.getHours() + 2);
        return d.toISOString().slice(0, 10);
    }
    const setDays = (fewDays) => {
        setValue('fewDays', fewDays)
        if(!fewDays)
            setValue('dateTo', null)
    }
    const close =() => {
        setDisplayForm(false)
    }
    const submit = async(data) => {
        setLoading(true)
        data.dateFrom.setHours(data.dateFrom.getHours() + 2);
        data.dateFrom = data.dateFrom.toISOString().slice(0, 10);
        if(!data.dateTo)
            data.dateTo = null;
        else
            data.dateTo = data.dateTo.slice(0, 10);
        try {
            const saveType = exhibition ? `update/${exhibition.id}` : 'new'
            await api.post(`/exhibitions/${saveType}`, data);
            toast({
                title: 'Sukces',
                description: `Wystawa została ${exhibition ? 'zapisana' : 'dodana'}.`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            if(!exhibition)
            close()
        } catch (error) {
            toast({
                title: 'Błąd',
                description: `Nie udało się zapisać.`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        setLoading(false)
    }
    return <>
        <form onSubmit={handleSubmit(submit)}>
            <FormControl my={6}>
                <FormLabel>Nazwa wystawy</FormLabel>
                <Input  {...register('name')} />
            </FormControl>
            <FormControl my={6}>
                <FormLabel>Miejsce</FormLabel>
                <Input  {...register('location')} />
            </FormControl>
            <FormControl my={6}>
                <FormLabel>Miejscowość</FormLabel>
                <Input  {...register('city')} />
            </FormControl>
            <FormControl my={6}>
                <FormLabel>Data wystawy</FormLabel>
                <Input type="date" {...register('dateFrom')} />
            </FormControl>
            <FormControl>
                <FormLabel>Cz wystawa trwa więcej niz 1 dzień ?</FormLabel>
                <Flex w={'full'} gap={'1rem'}>
                    <BgButton text={'TAK'} val={true} action={setDays} isActive={watch('fewDays')} />
                    <BgButton text={'NIE'} val={false} action={setDays} isActive={!watch('fewDays')}/>
                </Flex>
            </FormControl>
            {
                watch('fewDays') ? 
                <FormControl my={6}>
                    <FormLabel>Data zakończenia wystawy</FormLabel>
                    <Input type="date" {...register('dateTo')} />
                </FormControl> : ''
            }
            <FormControl my={6}>
                <FormLabel>Opis</FormLabel>
                <Textarea  {...register('description')} />
            </FormControl>
            <Flex justifyContent={'space-between'} mt={6}>
                <Button onClick={close}>Anuluj</Button>
                <Button colorScheme={'green'} type={'submit'} isLoading={loading} loadingText='Zapsiuje...' spinnerPlacement='end'>Zapisz</Button>
            </Flex>
        </form>
    </>
}

export default ExhibitionsForm;