import { Flex, Button, Container, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return <Container height={'100vh'}>
        <Flex h={'full'} flexFlow="column" justifyContent={'center'} alignItems={'center'}>
            <Heading
                display="inline-block"
                as="h2"
                size="2xl"
                backgroundClip="text">
                404
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
                Strona nie istnieje!
            </Text>
            <Text color={'gray.500'} mb={6}>
                Strona której szukasz nie została znaleziona.
            </Text>
            <Link to={'/'}>
                <Button
                    colorScheme="blue"
                    color="white"
                    variant="solid">
                    Strona Główna
                </Button>
            </Link>
        </Flex>
    </Container>
}

export default NotFound;