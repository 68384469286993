import { Box, Button, Card, CardBody, Container, Flex, Heading, Image, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr, useToast , Divider} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../utils/api";

const Shop = () => {
    const toast = useToast()
    const [previews, setPreviews] = useState([])
    const [orders, setOrders] = useState([])
    useEffect(()=>{
        getList()
    }, [])
    const getList = async() => {
        try {
            const res = await api.get('/shop/orders')
            setOrders(res.data.records)
        } catch (error) {
            console.log(error)
        }
    }
    const toggle = (id) => {
        const arr = [...previews];
        if(previews.includes(id)){
            const index = arr.findIndex(e => e === id)
            arr.splice(index, 1);
        }else{
            arr.push(id)
        }
        setPreviews(arr)
    }
    const sold = async(id) => {
        try {
            const res = await api.post('/shop/sold', {id})
            console.log(res)
            toast({
                title: 'Sukces.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            getList()
        } catch (error) {
            console.log(error)
        }
    }
    const cancel = async(id) => {
        try {
            const res = await api.post('/shop/cancel', {id})
            console.log(res)
            toast({
                title: 'Sukces.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            getList()
        } catch (error) {
            console.log(error)
        }
    }
    return <Container maxW={'7xl'} minHeight={'100vh'}>
        <Card>
            <CardBody>
                <Heading>Sklep</Heading>
                <Divider my={4} />
                <Table mt={9}> 
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Obraz</Th>
                            <Th>Cena</Th>
                            <Th>Zamawiający</Th>
                            <Th>Data zamówienia</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    {orders.length === 0 ? <TableCaption>BRAK ZAMÓWIEŃ</TableCaption> : ''}
                    {orders.map(order => <Tbody key={order.id}>
                        <Tr>
                            <Td>{order.id}</Td>
                            <Td>{order.name} ({order.categoryName})</Td>
                            <Td>{order.price}zł</Td>
                            <Td>{order.personFirstName + ' ' + order.personLastName}</Td>
                            <Td>{order.orderDate.slice(0, 10)}</Td>
                            <Td cursor={'pointer'} textDecoration={'underline'} color={'blue.800'} onClick={()=>toggle(order.id)}>
                                {previews.includes(order.id) ? 'UKRYJ' : "PDOGLĄD"}
                            </Td>
                        </Tr>
                        <Tr display={previews.includes(order.id) ? '' : 'none'}>
                            <Td colSpan={5}>
                                <Flex justifyContent={'space-between'} alignContent={'center'}> 
                                    <Flex gap={12}>
                                        <Box>
                                            <Image src={'http://api.hankagasienica.pl/media/' + order.url.replace('/www/hanka/img/gallery/','')} alt={order.name} maxH={'150px'} />
                                        </Box>
                                        <Box>
                                            <Text fontWeight={'bold'}>OBRAZ</Text>
                                            <Text mt={2}>{order.name}</Text>
                                            <Text mt={2} color={'gray.600'}>Kategoria</Text>
                                            <Text>{order.categoryName}</Text>
                                            <Text mt={2} color={'gray.600'}>Cena</Text>
                                            <Text>{order.price}zł</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight={'bold'}>DANE ZAMAWIAJĄCEGO</Text>
                                            <Text mt={2} color={'gray.600'}>Imię i nazwisko</Text>
                                            <Text>{order.personFirstName + ' ' + order.personLastName}</Text>
                                            <Text mt={2} color={'gray.600'}>Adres</Text>
                                            <Text>{order.personAddress}</Text>
                                            <Text>{order.personPostalCode + ' ' + order.personCity}</Text>
                                        </Box>
                                    </Flex>
                                    <Flex flexFlow={'column'} justifyContent={'center'} alignItems={'center'}>
                                        <Text fontWeight={'bold'}>Czy obraz sprzedano?</Text>
                                        {
                                            order.sold ? 
                                            <Text mt={2} color={'green.600'} fontWeight={'bold'}>TAK</Text>
                                            : order.canceled ?
                                            <Text mt={2} color={'red.600'} fontWeight={'bold'}>NIE</Text>
                                            :
                                            <Flex mt={2} gap={4}>
                                                <Button colorScheme="green" onClick={()=>sold(order.galleryId)}>TAK</Button>
                                                <Button colorScheme="red" onClick={()=>cancel(order.id)}>NIE</Button>
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </Td>
                        </Tr>
                    </Tbody>)}
                </Table>
            </CardBody>
        </Card>
    </Container>
}

export default Shop;