import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import ImagePreview from "./ImagePreview";

const ImagesList = ({images, category}) => {
    return <Flex flexFlow={'column'}>
        {category ? 
        <Flex gap={'.25rem'} my={4} justifyContent={'center'} alignItems={'center'}>
            <Text>Wybrana kategoria -</Text>
            <Text fontWeight={'bold'}>{category.name}</Text>
        </Flex>: ''}
        <SimpleGrid gap={'1.25rem'} columns={{base: 1, md: 2, lg: 2, xl: 2, '2xl':3}}>
            {images.map(image => <ImagePreview key={image.id} image={image} />)}
        </SimpleGrid>
    </Flex>
}

export default ImagesList;