import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ProtectedRoute from '../routes/ProtectedRoute';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Sidebar from "../components/Sidebar";
// import Cars from "../pages/cars";
import NotFound from "../pages/NotFound";
import Categories from "../pages/Categories";
import Images from "../pages/Images";
import Shop from "../pages/Shop";
import ImageDetails from "../pages/ImageDetails";
import Exhibitions from "../pages/Exhibitions";

const Routing = () => {
    const router = createBrowserRouter([
        {
            path: "/login",
            element: <Login />,
        },
        {
          path: "/",
        //   loader: () => {
        //     dispathAuth()
        //     return null;
        //   },
          element: (
            <ProtectedRoute>
                <Sidebar children={<Dashboard />} />
            </ProtectedRoute>
          ),
        },
        {
            path: "/categories",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<Categories />} />
              </ProtectedRoute>
            ),
        },
        {
            path: "/images",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<Images />} />
              </ProtectedRoute>
            ),
        },
        {
            path: "/images/:categoryID",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<Images />} />
              </ProtectedRoute>
            ),
        },
        {
            path: "/images/:categoryId/:imageId",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<ImageDetails />} />
              </ProtectedRoute>
            ),
        },
        {
            path: "/shop",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<Shop />} />
              </ProtectedRoute>
            ),
        },
        {
            path: "/exhibitions",
          //   loader: () => {
          //     dispathAuth()
          //     return null;
          //   },
            element: (
              <ProtectedRoute>
                  <Sidebar children={<Exhibitions />} />
              </ProtectedRoute>
            ),
        },
        {
          path: "/*",
          element: <NotFound />,
        },
    ]);
    return <RouterProvider router={router} />;
}

export default Routing;