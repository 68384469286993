import { Button, Card, CardBody, Flex, FormControl, FormLabel, Image, Input, useToast, useDisclosure,  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from "react";
import api from "../utils/api";

const schema = yup.object({
    name: yup.string().required(),
})

const CategoryForm = ({category, close}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [editMode, setEditMode] = useState(false);
    const [img, setImg] = useState(null);
    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(()=>{
        if(category.name){
            const name = category.name;
            reset({name})
            setEditMode(true)
        }else{
            setEditMode(false)
        }
    },[category])
    const addImage = () => {
        document.getElementById('uploadImageInput').click()
    }
    const setFile = e => {
        if(e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
                file: e.target.files[0]
            })
        }
    }
    const submit = (data) => {
        if(editMode)
            update(data)
        else
            newCategory(data)
    }
    const newCategory = async(data) => {
        const formData = new FormData();
        formData.append('name', data.name)
        formData.append('image', img.file)
        const res = await api.post('/categories/add', formData)
        if(res.status === 201)
        done()
    }
    const update = async(data) => {
        const {name} = data;
        const res = await api.post(`/categories/update/${category.ID}`, {name})
        if(res.status === 200)
        done()
    }
    const done = (del=false) => {
        toast({
            title: 'Sukces.',
            description: `Kategoria została ${del ? 'usunięta' : editMode ? 'zapisana' : 'dodana'}.`,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
        close()
    }
    return <>
    <Card>
        <CardBody>
            <form onSubmit={handleSubmit(submit)}>
                <Flex flexFlow={'column'} gap={'1rem'}>
                    <FormControl>
                        <FormLabel>Nazwa</FormLabel>
                        <Input {...register('name')} />
                    </FormControl>
                    {
                        editMode ? '' :
                        <FormControl>
                            <FormLabel>Obraz</FormLabel>
                            { img ? <Image src={img.src} maxWidth={'300px'} maxH={'300px'} objectFit={'cover'} /> : '' }
                            <Button my={3} w={'full'} colorScheme={'blue'} variant={'outline'} onClick={addImage}>{img ? 'ZMIEŃ' : 'DODAJ'} OBRAZ</Button>
                            <input style={{display: 'none'}} id="uploadImageInput" type={'file'} accept="image/*" onChange={setFile} />
                        </FormControl>
                    }
                    <Flex justifyContent={'space-between'} mt={5}>
                        <Button onClick={()=>close()}>Anuluj</Button>
                        <Button colorScheme={'green'} type={'submit'}>Zapisz</Button>
                    </Flex>
                </Flex>
            </form>
        </CardBody>
    </Card>
    {editMode ? <Button my={7} colorScheme={'red'} variant={'outline'} w={'full'} display={'none'} onClick={onOpen}>USUŃ KATEGORIE</Button> : ''}
    <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Usuń kategorie
                </AlertDialogHeader>
                <AlertDialogBody>
                    Czy napewno chcesz usunąć kategorie - {category.name} ? 
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button onClick={onClose}>
                        Nie
                    </Button>
                    <Button colorScheme='red' onClick={onClose} ml={3}>
                        Tak
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
    </>
}

export default CategoryForm;