import { Flex, Button, Container, Heading, useToast, Image, useDisclosure,  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageForm from "../components/ImageForm";
import api from "../utils/api";
// import { Link } from "react-router-dom";

const ImageDetails = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { categoryId, imageId } = useParams();
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState(null)
    useEffect(()=>{
        const getData = async() => {
            try {
                const res = await api.get(`/gallery/image/${categoryId}/${imageId}`)
                setItem(res.data.records[0]);
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getData()
    },[])
    const deleteImage = async() => {
        await api.delete(`/gallery/${categoryId}/${imageId}`)
        onClose()
        toast({
            title: 'Sukces.',
            description: `Obraz został usunięty.`,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
        navigate(`/images/${categoryId}`)
    }
    if(loading) return <>Ładuje...</>
    return <Container maxW={'7xl'} minHeight={'100vh'}>
        <Flex mb={4} justifyContent={'end'}>
            <Button onClick={()=>navigate(`/images/${categoryId}`)}>Powrót do obrazów</Button>
        </Flex>
        <Flex gap={'1rem'} flexFlow={{base: 'column', md: 'row'}}>
            <Flex flex={1} alignItems={'start'}>
                <Image src={'http://api.hankagasienica.pl/media/' + item.url.replace('/www/hanka/img/gallery/','')} w={'full'} height={'auto'} objectFit={'contain'} boxShadow={'xl'} />
            </Flex>
            <Flex flex={2} flexFlow={'column'} gap={'1rem'}>
                <ImageForm categoryID={categoryId} imageData={item} editMode />
                <Button mt={6} colorScheme={'red'} variant={'outline'} w={'full'} onClick={onOpen}>USUŃ OBRAZ</Button>
            </Flex>
        </Flex>
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Usuń obraz
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        Czy napewno chcesz usunąć obraz - {item.name} ? 
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            Nie
                        </Button>
                        <Button colorScheme='red' onClick={deleteImage} ml={3}>
                            Tak
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </Container>
}

export default ImageDetails;