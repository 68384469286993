import React, {useEffect} from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Divider,
  Image
} from '@chakra-ui/react';
import { Link, useLocation } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FiLogOut, FiMenu } from "react-icons/fi";
import {BsImages, BsCart, BsBuildings} from 'react-icons/bs'
import {HiOutlineDuplicate} from 'react-icons/hi'

const Sections = [
    { name: 'Start', icon: MdDashboard, href: '/', role: [] },
    { name: 'Kategorie', icon: HiOutlineDuplicate, href: '/categories', role: [] },
    { name: 'Obrazy', icon: BsImages, href: '/images', role: [] },
    { name: 'Sprzedaż', icon: BsCart, href: '/shop', role: [] },
    { name: 'Wystawy', icon: BsBuildings, href: '/exhibitions', role: [] },
];

export default function Sidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  useEffect(() => {
    onClose()
  }, [location, onClose]);
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.50', 'gray.100')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 64 }} p={{base: 4, md: 8}} minH={'100vh'} pb={24} >
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
        bg={'white'}
      w={{ base: 'full', md: 64 }}
      pos="fixed"
      h="full"
      overflowY={'auto'}
      fontSize={'lg'}
      {...rest}>
      <Flex h={'100%'} px={6} py={8} pt={{base: 4, md: 8}} flexFlow={'column'} justifyContent={'space-between'}>
        <Flex flexFlow={'column'}>
            <Flex justifyContent={'end'}>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <Flex alignItems={'center'} gap={'.5rem'}>
                <Flex fontSize={'md'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                    <Image flex={1} src={'https://www.hankagasienica.pl/_next/static/media/logo_black.6f9efa0b.svg'} alt={'logo'} />
                </Flex>
            </Flex>
            <Divider my={6} />
            {Sections.map((section, index) => {
                return <NavItem key={section.name} icon={section.icon} href={section.href}>
                    {section.name}
                </NavItem>
            })}
        </Flex>
        <Flex flexFlow={'column'}>
            {/* <NavItem icon={FiLogOut} onClick={() => console.log('wyloguj')}>
                Wyloguj
            </NavItem> */}
        </Flex>
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, href, ...rest }) => {
  return (
    <Link to={href ? href : ''} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        py={2}
        px={3}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'blue.500',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 24 }}
        height="20"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor={'rgb(163, 130, 58)'}
        justifyContent="flex-start"
        {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Flex w="full" justifyContent={'center'}>
                <Image maxW={'200px'} src={'https://www.hankagasienica.pl/_next/static/media/logo_black.6f9efa0b.svg'} alt={'logo'} />
            </Flex>
    </Flex>
  );
};