import { Table, Thead, Tr, Td, Th, Tbody, TableCaption } from "@chakra-ui/react";

const ExhibitionsTable = ({exhibitions, openForm}) => {
    const setDate = (date) => {
        const d = new Date(date);
        d.setHours(d.getHours() + 2);
        return d.toISOString().slice(0, 10);
    }
    const open = (exhibition) => {
        openForm(exhibition)
    }
    return <Table mt={7} >
        <Thead>
            <Tr>
                <Th>ID</Th>
                <Th>Nazwa</Th>
                <Th>Miejsce</Th>
                <Th>Data</Th>
            </Tr>
        </Thead>
        {exhibitions.length === 0 ? <TableCaption>BRAK WYSTAW</TableCaption> : ''}
        <Tbody>
            {exhibitions.map(exhibition => 
                <Tr key={exhibition.id} onClick={() => open(exhibition)}>
                    <Td>{exhibition.id}</Td>
                    <Td>{exhibition.name}</Td>
                    <Td>{exhibition.location} ({exhibition.city})</Td>
                    <Td>
                        {Date.parse(exhibition.dateFrom) ? setDate(exhibition.dateFrom) : ''}
                        {exhibition.dateTo && Date.parse(exhibition.dateTo) ? ` - ${setDate(exhibition.dateTo)}` : ''}
                    </Td>
                </Tr>    
            )}
        </Tbody>
    </Table>
}

export default ExhibitionsTable;