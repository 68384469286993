import { Flex, Button, Container, Heading, Divider } from "@chakra-ui/react";
import { useState } from "react";
import CategoriesList from "../components/CategoriesList";
import CategoryForm from "../components/CategoryForm";

const Categories = () => {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [displayForm, setDisplayForm] = useState(false)
    const openForm = category => {
        if(!category)
            setSelectedCategory(null)
        else{
            setSelectedCategory(category)
        }
        setDisplayForm(true)
    }
    const close = () => {
        setDisplayForm(false)
    }
    return <Container maxW={'5xl'} minHeight={'100vh'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Heading>Kategorie</Heading>
            <Button colorScheme={'blue'} onClick={openForm}>Dodaj kategorie</Button>
        </Flex>
        <Divider my={4} />
        {displayForm ? 
            <CategoryForm category={selectedCategory} close={close} /> :
            <CategoriesList clickAction={openForm} /> }
    </Container>
}

export default Categories;