import { Box } from "@chakra-ui/react"

const BgButton = ({text, val, action, isActive=false}) => {
    return <Box 
        py={3}
        px={4}
        boxShadow={'md'}
        border={'1px solid'}
        borderColor={'gray.200'}
        cursor={'pointer'}
        bg={isActive ? 'blue.500' : ''}
        color={isActive ? 'white' : ''}
        onClick={()=>action(val)}
    >{text}</Box>
}

export default BgButton;