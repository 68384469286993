import { Button, Card, CardBody, Container, Flex, Heading, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../utils/api";
import ExhibitionsForm from "../components/ExhibitionsForm";
import ExhibitionsTable from "../components/ExhibitionsTable";

const Exhibitions = () => {
    const [displayForm, setDisplayForm] = useState(false)
    const [exhibitions, setExhibitions] = useState([])
    const [exhibition, setExhibition] = useState(null)
    useEffect(()=>{
        getData();
    },[displayForm])
    const getData = async () => {
        try {
            const res = await api.get('/exhibitions/list')
            setExhibitions(res.data.records)
        } catch (error) {
            console.log(error)
        }
    }
    const openForm = (selectedExhibition) => {
        if(selectedExhibition)
            setExhibition(selectedExhibition)
        else
            setExhibition(null)

        setDisplayForm(true)
    }
    return <Container maxW={'7xl'} minHeight={'100vh'}>
        <Card>
            <CardBody>
                <Flex justifyContent={'space-between'}>
                    <Heading>Wystawy</Heading>
                    {!displayForm ? 
                    <Button colorScheme='blue' onClick={openForm}>Nowa wystawa</Button>:'' }
                </Flex>
                <Divider my={4} />
                {displayForm ? <ExhibitionsForm setDisplayForm={setDisplayForm} exhibition={exhibition} /> : <ExhibitionsTable exhibitions={exhibitions} openForm={openForm} />}
            </CardBody>
        </Card>
    </Container>
}

export default Exhibitions;